
<template>
  <div>
    <v-dialog v-model="dialog" scrollable persistent :fullscreen="!this.$vuetify.breakpoint.smAndUp">
      <v-card style="border-radius:5px" class="pb-2">
        <v-card-title primary-title class='primary white--text'>
          <span class="text-subtitle">Detalhes do curso</span>
          <v-spacer></v-spacer>
          <v-btn text class="ml-2" icon color="white" @click="closeDialog" rounded><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <course-details :selected_course="course"></course-details>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDialog"><v-icon left>mdi-close</v-icon>Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'DialogShowCourseDetails',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    course: {
      type: Object,
      default: null
    }
  },

  components: {
    CourseDetails: () => import('../../courses/CourseDetails.vue'),
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style scoped>

</style>